* {
  box-sizing: border-box;
  font-family: "Stag Sans Book", "Helvetica Neue", sans-serif;
}

html {
  font-size: 8px;
  height: 0 !important;
  scroll-behavior: smooth;
}

@media (prefers-reduced-motion) {
  html {
    scroll-behavior: initial;
  }
}

body {
  background: #f7f7f7 !important;
  padding: 0;
  margin: 0;
  line-height: 1.5;
  font-size: 2rem;
}

dl,
dd,
dt {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  color: inherit;
}

a {
  text-decoration: none;
}

input {
  font: inherit;
}

@media screen and (max-width: 768px) {
  us-button {
    display: none;
  }
}

.l-container {
  width: calc(100% - 4rem);
  max-width: 1200px;
  margin-inline: auto;
}

.u-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
